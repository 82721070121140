<template>
  <div class="container">
    <section class="login-main-wrapper">
      <div class="main-container">
        <div class="login-process">
          <div class="login-main-container">
            <div class="thankyou-wrapper">
              <h1 class="flex justify-content-center">
                <img src="@/assets/icons/Oonpay logo.png" height="70px" width="100px" alt="thanks" />
              </h1>
              <p class="mt-5">
                Your request to join our network has been accepted. We cant wait
                to share your services with our large customers over the
                world.Your details provided will be reviewed and a confirmation
                email will be sent shortly.
              </p>

              <div class="clr"></div>
            </div>
            <router-link to="/">Back to home</router-link>
            <div class="clr"></div>
          </div>
        </div>
        <div class="clr"></div>
      </div>
    </section>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.thankyou-wrapper {
  width: 100%;
  height: auto;
  margin: auto;
  background: #ffffff;
  padding: 10px 0px 50px;
}
.thankyou-wrapper h1 {
  font: 100px Arial, Helvetica, sans-serif;
  text-align: center;
  color: #333333;
  padding: 0px 10px 10px;
}
.thankyou-wrapper p {
  font: 26px Arial, Helvetica, sans-serif;
  text-align: center;
  color: #333333;
  padding: 5px 10px 10px;
}
 a {
  font: 26px Arial, Helvetica, sans-serif;
  text-align: center;
  color: #ffffff;
  display: block;
  text-decoration: none;
  width: 250px;
  background: #e47425;
  margin: 10px auto 0px;
  padding: 15px 20px 15px;
  border-bottom: 5px solid #f96700;
}
 a:hover {
  font: 26px Arial, Helvetica, sans-serif;
  text-align: center;
  color: #ffffff;
  display: block;
  text-decoration: none;
  width: 250px;
  background: #f96700;
  margin: 10px auto 0px;
  padding: 15px 20px 15px;
  border-bottom: 5px solid #f96700;
}
</style>
